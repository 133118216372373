<div mat-dialog-title>
    <button class="btn btn-close shadow-none opacity-100 position-absolute top-0 end-0 m-3" (click)="close()"></button>
    <div class="pb-1 me-2">
        <h3 class="font-secondary pe-4" style="display: flex; align-content: center; flex-wrap: wrap;">
            {{offer.name}}
        </h3>
        <p>{{offer.description}}</p>
    </div>
</div>

<section mat-dialog-content class="overflow-auto theme-scrollbar">
    @if(showBuy && this.data.promoItemList.buy!==undefined && this.data.promoItemList.buy.length>0) {
        @for (promoItem of this.data.promoItemList.buy; track $index) {
        <div class="p-2 p-lg-4 border rounded-1 overflow-hidden mb-3" id="buy">
            <div class="row cart-item-row">
                <div class="col-auto position-relative">
                    <img [src]="promoItem.item?.images !== null ? imageUrl+((promoItem.item?.defaultImage !== null)? promoItem.item?.defaultImage:promoItem.item.images[0]): 'assets/images/no-image-available.png'"
                        class="dish-img" width="140" alt="" onerror="this.src='assets/images/no-image-available.png'"
                        style="height: 70px;width: 70px;">
                </div>
                <div class="col" style="display: flex;flex-direction: column;overflow: hidden;">
                    <h5 class="pb-0 mb-0" style="flex: 1;">
                        {{promoItem.item.name}}
                    </h5>
                    <div style="display: flex; padding-right: 18px;">
                        <h3 style="flex: 1;" class="mb-0 me-4 font-secondary d-inline-block align-middle amount">
                            {{currencySymbol}}{{promoItem.item.fixedPrice| number :
                            '1.2-2'}}
                        </h3>
                        <input type="radio" [checked]="isCheckedBuy" 
                        class="col-auto col-lg col ms-3 font-secondary py-1 py-sm-2 px-4 offer-select-radio" name="buy" value={{promoItem.item}}
                        (click)="onAddBuyItem(promoItem.item,promoItem.qty)">
                        <!-- <input type="radio" [checked]="buyItem?.ooSaleItemId === promoItem.item.id" 
                            class="col-auto col-lg col ms-3 font-secondary py-1 py-sm-2 px-4 offer-select-radio"
                            (click)="onAddBuyItem(promoItem.item,promoItem.qty)"> -->
                    </div>
                </div>
            </div>
        </div>
        }
    }

    @if( showOffer && this.data.promoItemList.offer!==undefined && this.data.promoItemList.offer.length>0){
        @for ( promoItem of this.data.promoItemList.offer; track $index) {
        <div class="p-2 p-lg-4 border rounded-1 overflow-hidden mb-3" id="offer">
            <div class="row cart-item-row" style="flex-wrap: nowrap;">
                <div class="col-auto position-relative">
                    <img [src]="promoItem.item?.images !== null ? imageUrl+((promoItem.item?.defaultImage !== null)? promoItem.item?.defaultImage:promoItem.item.images[0]): 'assets/images/no-image-available.png'"
                        class="dish-img" width="140" alt="" onerror="this.src='assets/images/no-image-available.png'"
                        style="height: 70px;width: 70px;">
                </div>

                <div class="col" style="display: flex;flex-direction: column;overflow: hidden;">
                    <h5 class="pb-0 mb-0 me-1" style="flex: 1;">
                        {{promoItem.item.name}}
                    </h5>
                    <div style="display: flex; padding-right: 18px;">
                        <del class="mb-0 me-2 font-secondary d-inline-block align-middle fs-4 amount" style="align-content: center;">
                            {{currencySymbol}}{{promoItem.item.fixedPrice| number :
                            '1.2-2'}}
                        </del>
                        <h3 style="flex: 1;" class="mb-0 font-secondary d-inline-block align-middle amount">
                            {{currencySymbol}}{{getOfferAmountOfItem(promoItem) | number :
                            '1.2-2'}}
                        </h3>
                        <input type="radio" class="col-auto col-lg col ms-3 font-secondary py-1 py-sm-2 px-4 offer-select-radio"
                            id="offerButton" [checked]="isCheckedOffer" name="offer" value={{promoItem.item}}
                            (click)="onAddOfferItem(promoItem.item,promoItem.qty)">
                        <!-- <input type="radio" [checked]="buyItem?.ooSaleItemId === promoItem.item.id"
                            class="col-auto col-lg col ms-3 font-secondary py-1 py-sm-2 px-4"
                            (click)="onAddBuyItem(promoItem.item,promoItem.qty)"> -->
                    </div>
                </div>

            </div>
        </div>
        }
    }

</section>
<div mat-dialog-footer class="py-2 showOfferButton">
    @if (showBuy ) {
    <button class="col-auto col-lg col btn btn-primary rounded-pill font-secondary py-1 py-sm-2 px-4" id="offerButton"
        (click)="toggleUI()" [disabled]="buyItem==undefined">View Offers</button>
    }
    @if (showOffer) {
    @if (this.data.promoItemList.buy!==undefined && this.data.promoItemList.buy.length>0) {
    <button class="col-auto col-lg col btn btn-primary rounded-pill font-secondary py-1 py-sm-2 px-4" id="offerButton"
        (click)="toggleUI()">Back</button>
    }
    @if(this.data.source.type=='cart-item'){
    <button class="col-auto col-lg col btn btn-primary rounded-pill font-secondary py-1 py-sm-2 px-4" id="offerButton"
        (click)="close()">No Thank You</button>
    }
    <button class="col-auto col-lg col btn btn-primary rounded-pill font-secondary py-1 py-sm-2 px-4 ms-3"
        id="offerButton" [disabled]="offerItem==undefined" (click)="apply()">Add To Cart</button>
    }
</div>