<!-- <div class="position-sticky back-strip bg-white" id="title">
    <div class="container pt-3 pb-2 font-secondary" style="display: flex;">
        <button class="btn btn-outline-dark rounded-pill py-2 px-3" (click)="back()"><img
                src="assets/icons/back_arrow_icon.svg" class="me-lg-2 white-icon" alt=""><span
                class="align-middle d-none d-lg-inline">BACK</span></button>
         <div class="btn btn-outline-dark rounded ms-3" (click)="itemListback()">
            <img src="assets/icons/food-menu.svg" class="" alt="menu" style="width: 28px; height: 28px;"
                class="me-lg-2 white-icon"><span class="align-middle d-none d-lg-inline ms-2">MENU</span>
        </div> 
        <p class="d-inline-block ms-lg-4 mb-0 fs-5 ms-3 page-heading align-middle" style="margin-top: 7px;">checkout</p>
    </div>
</div> -->

<div class="back-strip font-secondary bg-white firstDiv">
    <div class="container py-2 py-lg-3 d-flex" style="align-items: center;">
        <button class="btn btn-outline-dark rounded-pill py-2 px-3" (click)="back()"><img
                src="assets/icons/back_arrow_icon.svg" class="me-lg-2 white-icon" alt=""><span
                class="align-middle d-none d-lg-inline">BACK</span></button>
        <p class="d-inline-block ms-lg-5 mb-0 fs-5 page-heading text-center align-middle">checkout</p>
    </div>
</div>

<div class="container pt-3 pb-5 pb-lg-4 pt-lg-4 overflow-x-hidden cart-wrapper">
    <div class="row cart-row">
        <div class="col-lg-7">
            <h3 #orderDetailTitleDiv class="font-secondary mb-2">Order Details</h3>
            <div class="items-list overflow-auto">
                <!-- <div class="p-3 p-lg-4 border rounded-1 mb-3 overflow-hidden">
                    <div class="row cart-item-row">
                        <div class="col-auto">
                            <img src="assets/images/temp/13.png" width="140" class="dish-img" alt="">
                        </div>
                        <div class="col">
                            <h5 class="pb-1">Small Teriyaki Chicken on Rice with Mayo</h5>
                            <p class="mb-2 mb-sm-4 pb-lg-2">
                                <span>Extra Teriyaki Sauce (POT)</span>
                                <span>,&nbsp; Extra Spicy Mayo (POT) x2</span>
                            </p>
                            <h3 class="mb-0 me-4 font-secondary d-inline-block align-middle">$7.70
                            </h3>
                            <div class="dropdown-center font-secondary d-inline-block">
                                <button class="btn btn-outline-dark dropdown-toggle rounded-pill fs-5" type="button"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    <span class="pe-3">2</span>
                                </button>
                                <ul class="dropdown-menu menu-2 text-center">
                                    <li>
                                        <h5 class="dropdown-item mb-0">1</h5>
                                    </li>
                                    <li>
                                        <h5 class="dropdown-item mb-0">2</h5>
                                    </li>
                                    <li>
                                        <h5 class="dropdown-item mb-0">3</h5>
                                    </li>
                                    <li>
                                        <h5 class="dropdown-item mb-0">4</h5>
                                    </li>
                                    <li>
                                        <h5 class="dropdown-item mb-0">5</h5>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-auto ">
                            <button class="btn btn-edit p-2 rounded-circle bg-secondary mb-2"
                                style="--bs-bg-opacity:.1;" routerLink="/itemDetail/{{5}}">
                                <img src="assets/icons/edit_icon.svg" width="24" class="m-lg-1" alt="">
                            </button>
                            <button class="btn btn-edit p-2 rounded-circle bg-secondary d-block"
                                style="--bs-bg-opacity:.1;" (click)="deleteConfirmation()">
                                <img src="assets/icons/delete_icon.svg" width="24" class="m-lg-1" alt="">
                            </button>
                        </div>
                    </div>
                </div> -->
                @for(cartItem of cartItemInfo; track cartItem.ooSaleItemId){
                @if (cartItem.hasError) {
                <app-cart-panel [cartItem]="cartItem" (delete)="deleItemClickEvent($event)"
                    (change)="onCartPanelChange(cartItem)">
                </app-cart-panel>
                }
                }
                @for(cartItem of cartItemInfo; track cartItem.ooSaleItemId){
                @if (!cartItem.hasError) {
                <app-cart-panel [cartItem]="cartItem" (delete)="deleItemClickEvent($event)"
                    (change)="onCartPanelChange(cartItem)">
                </app-cart-panel>
                }
                }
            </div>
        </div>
        <div class="col-lg-5" #cartSummaryDiv [class.sticky]="isProceedBtnVisible && !isDetailDiVisible">
            <!-- <div class="d-flex justify-content-between font-secondary pb-1">
                <h3 class="my-auto">Reward Summary</h3>
            </div> -->
            @for (reward of appliedOffers; track $index) {
            <div class="d-flex justify-content-between font-secondary pb-1">
                <h3 class="my-auto">Reward Summary</h3>
                <!-- <button class="btn btn-outline-dark rounded-pill py-2 px-4" (click)="rewardDialog()">change</button> -->
            </div>
            <app-reward-card [reward]="reward"></app-reward-card>
            }
            <div class="d-flex justify-content-between font-secondary pb-1">
                <h3 class="my-auto">Order Summary</h3>
                <!-- <button class="btn btn-outline-dark rounded-pill py-2 px-4" (click)="rewardDialog()">change</button> -->
            </div>
            @if (offerAmount==0) {
            <div class="bg-secondary text-white p-3 rounded-1 mb-4">
                <p class="fs-5">
                    <span>Sub Total</span>
                    <span class="float-end">
                        {{ currencySymbol}}{{ cartService.getCartTotal() | number:'1.2-2' }}</span>
                </p>
                <p class="fs-4 mb-2 mb-lg-4">
                    <span>Net Total</span>
                    <span class="float-end">{{ currencySymbol
                        }}{{ cartService.getCartTotal()- offerAmount | number:'1.2-2'}}</span>
                </p>
                <p class="mb-0 text-primary">Your saved {{ currencySymbol
                    }}{{offerAmount|number:"1.2-2"}} in this order</p>
            </div>
            }
            @else {
            <div class="bg-secondary text-white p-3 rounded-1 mb-4">
                <p class="fs-5">
                    <span>Sub Total</span>
                    <span class="float-end">{{ currencySymbol
                        }}{{cartService.getCartTotal()|number:"1.2-2"}}</span>
                </p>
                <p class="fs-4 mb-2 mb-lg-4">
                    <span>Net Total</span>
                    <span class="float-end">{{ currencySymbol}}
                        {{(cartService.getCartTotal()- offerAmount)|number:'1.2-2'}}</span>
                </p>
                <p class="mb-0 text-primary">Your saved {{ currencySymbol
                    }}{{offerAmount|number:'1.2-2'}} in this order</p>
            </div>
            <!-- <div class="bg-secondary text-white p-3 rounded-1 mb-4">
                <p class="fs-5">
                    <span>Sub Total</span>
                    <span class="float-end">{{ currencySymbol
                        }}{{ cartService.getCartTotal() | number: "1.2-2" }}</span>
                </p>
                <p class="fs-5">
                    <span style="color: #F29D22;">Reward Discount</span>
                    <span class="float-end" style="color: #F29D22;">{{ currencySymbol
                        }}{{offerAmount}}</span>
                </p>
                <p class="fs-4 mb-3 pt-3 border-top">
                    <span>Total After Rewards</span>
                    <span class="float-end">{{ currencySymbol
                        }}{{ cartService.getCartTotal()- offerAmount | number: "1.2-2"}}</span>
                </p>
                <p class="fs-5 mb-0 pt-3 border-top">
                    <span>Total Includes GST of</span>
                    <span>
                        {{ currencySymbol
                        }}{{ cartService.getCartTotalTax() | number: "1.2-2" }}
                    </span>
                </p>
            </div> -->
            }
            <h3 class="font-secondary my-auto pt-2 pt-lg-1 font-secondary mb-0">Pickup</h3>
            <div class="card rounded-1 flex-row p-2 p-lg-3 mb-2">
                <div class="my-auto">
                    <p class="mb-0 text-secondary fs-4" style="--bs-secondary-rgb:63 ,68, 67">Date/Time</p>
                    <p class="mb-0 pickeup-time mt-2">{{ cart.deliveryDate.label }}&nbsp;&nbsp;{{("1900-01-01T" +
                        cart.deliveryTime) | date: 'h:mm a'}}</p>
                </div>
                <button class="btn btn-edit p-2 rounded-circle bg-secondary ms-auto mb-auto"
                    (click)="scheduleDialog(cart.businessType.code,cart.shop)" style="--bs-bg-opacity:.1;">
                    <img src="assets/icons/edit_icon.svg" width="24" class="m-lg-1" alt="">
                </button>
            </div>
            <div class="card rounded-1 flex-row flex-wrap p-2 p-lg-3 mb-2">
                <div (onOpen)="onShopDetailTabOpen($event)" (onClose)="onShopDetailTabClose($event)">
                    <p class="mb-0 text-secondary fs-4" style="--bs-secondary-rgb:63 ,68, 67">Shop</p>
                    <p class="mb-0 pickeup-time mt-2">{{cart.shop.name}}</p>
                </div>
                <!-- <button class="btn btn-edit p-2 rounded-circle bg-secondary ms-auto mb-auto" style="--bs-bg-opacity:.1;"
                    (click)="shopSelection(cart.shop.id)"> -->
                <button class="btn btn-edit p-2 rounded-circle bg-secondary ms-auto mb-auto" style="--bs-bg-opacity:.1;"
                    (click)="changeShop()">
                    <img src="assets/icons/edit_icon.svg" width="24" class="m-lg-1" alt="">
                </button>
                @if (shopPickupLocation.length!=0) {
                    <div class="w-100 mt-3 mt-lg-4 d-flex align-items-center">
                        <p class="mb-0 text-secondary fs-4" style="--bs-secondary-rgb:63 ,68, 67"><img src="assets/icons/location_icon.svg"
                            width="17" alt=""><span class="align-middle ms-2">Location</span></p>
                    </div>
                <div class="w-100 mt-3 mt-lg-4 d-flex align-items-center" 
                style="flex-wrap: wrap;gap: 10px;">
                 
                    @for (shopPickupLoc of shopPickupLocation; track shopPickupLoc.id) {
                    <div class="form-check radio mb-0 me-2 me-xl-4">
                        <input class="form-check-input shadow-none" type="radio" name="flexRadioDefault" id="inStore"
                            (click)="setPickUpLocationToCart(shopPickupLoc)"
                            [checked]="this.selectedPickUplocation==shopPickupLoc?true:false">
                        <label class="form-check-label text-nowrap" for="inStore">
                            {{shopPickupLoc?.title}}
                        </label>
                    </div>
                    }
                    
                </div>
            }
            </div>

            <mat-expansion-panel class="mat-elevation-z border mb-2 faq-indicator" [class]="" [expanded]="step === 0"
                (opened)="setStep(0)">
                <mat-expansion-panel-header class="my-2 bg-white px-3" [class]="">
                    <div>
                        <p class="mb-1 fs-4">Customer Details</p>
                        <p class="mb-0 fs-5 mt-2">{{ customerName }}</p>
                    </div>
                </mat-expansion-panel-header>
                @if (cartService.isGuestLogin()) {
                <form class="pb-0 mb-0" id="guestForm" #guestForm="ngForm" autocomplete="off" (ngSubmit)="guestUser()">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="mb-3 pb-1">
                                <label for="emailId" class="form-label mb-1">First Name</label>
                                <input type="email" class="form-control shadow-none rounded-1" placeholder="" #firstName
                                    name="firstName" id="firstName" [(ngModel)]="selectedCustomer.firstName"
                                    [formControl]="formControl?.firstName" required autofocus>
                                <mat-error *ngIf="formControl?.firstName.invalid"
                                    class="form-field-error fs-5">{{getFirstNameError()}}</mat-error>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-3 pb-1">
                                <label for="phoneNumber" class="form-label mb-1">Last Name</label>
                                <input type="text" class="form-control shadow-none rounded-1" id="phoneNumber"
                                    name="lastName" [(ngModel)]="selectedCustomer.lastName"
                                    [formControl]="formControl.lastName" placeholder="">
                            </div>
                        </div>
                    </div>
                    <div class="mb-3 pb-1">
                        <label for="email" class="form-label mb-1">Email Address</label>
                        <input type="text" class="form-control shadow-none rounded-1" id="email"
                            [(ngModel)]="selectedCustomer.email" placeholder="" [formControl]="formControl.email"
                            required>
                        <mat-error *ngIf="formControl.email.invalid"
                            class="form-field-error fs-5">{{getEmailError()}}</mat-error>
                    </div>
                    <div class="mb-3 pb-1">
                        <label for="phone" class="form-label mb-1">Phone Number</label>
                        <input type="tel" class="form-control shadow-none rounded-1" id="phone" placeholder=""
                            #guestPhone [(ngModel)]="selectedCustomer.phoneNumber"
                            [formControl]="formControl.phoneNumber" required pattern="[0-9]*"
                            (keypress)="preventNonNumeric($event)">
                        <br>
                        <mat-error *ngIf="formControl.phoneNumber.invalid"
                            class="form-field-error fs-5">{{getPhoneNumberError()}}</mat-error>
                    </div>
                </form>
                }
                @else {
                <form class="pb-0 mb-0" id="guestForm" #guestForm="ngForm" autocomplete="off" (ngSubmit)="guestUser()">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="mb-3 pb-1">
                                <label for="emailId" class="form-label mb-1">First Name</label>
                                <input type="email" class="form-control shadow-none rounded-1" placeholder="First Name"
                                    #firstName name="firstName" id="firstName" [(ngModel)]="selectedCustomer.firstName"
                                    [formControl]="formControl?.firstName" required readonly>
                                @if (formControl?.firstName.invalid) {
                                <mat-error>{{getFirstNameError()}}</mat-error>
                                }
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-3 pb-1">
                                <label for="phoneNumber" class="form-label mb-1">Last Name</label>
                                <input type="text" class="form-control shadow-none rounded-1" id="phoneNumber"
                                    name="lastName" [(ngModel)]="selectedCustomer.lastName"
                                    [formControl]="formControl.lastName" placeholder="Last Name" readonly>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3 pb-1">
                        <label for="email" class="form-label mb-1">Email Address</label>
                        <input type="text" class="form-control shadow-none rounded-1" id="email"
                            [(ngModel)]="selectedCustomer.email" placeholder="Yukisuzuki@gmail.com"
                            [formControl]="formControl.email" required readonly>
                        @if (formControl.email.invalid) {
                        <mat-error>{{getEmailError()}}</mat-error>
                        }
                    </div>
                    <div class="mb-3 pb-1">
                        <label for="phone" class="form-label mb-1">Phone Number</label>
                        <input type="tel" class="form-control shadow-none rounded-1" id="phone" placeholder="0123456789"
                            #guestPhone [(ngModel)]="selectedCustomer.phoneNumber"
                            [formControl]="formControl.phoneNumber" required readonly>
                        <br>
                        @if (formControl.phoneNumber.invalid) {
                        <mat-error>{{getPhoneNumberError()}}</mat-error>
                        }
                    </div>
                </form>
                }
            </mat-expansion-panel>

            <div class="card rounded-1  p-2 p-lg-3 mb-2">
                <form>

                    <p class="mb-0  text-secondary fs-4 ps-2">Note to Restaurant</p>
                    <textarea class="form-control shadow-none rounded-1 p-3" style="resize: none;" id="message" rows="5"
                        placeholder="Write your note here.." matInput [(ngModel)]="cartService.cart.remarks"
                        name="remarks" (keydown)="onKeydown($event)" style="border:none;"></textarea>
                </form>
            </div>

            <!-- <p class="mb-4 text-danger">
                Please note that packaging may differ from visual, sustainable packaging is now in use.
            </p> -->
            <div class="cart-info-panel  rounded-1"><img src="assets/images/icons/info-1.png">
                <p class="title mt-2">Please note that packaging may differ from visual, sustainable packaging is now in
                    use.</p>
            </div>
            <div *ngIf="isContinueOptioniVisible" class="login-or-gust-container pt">
                <div class="login-or-gust">
                    <div class="glyphicon glyphicon-remove login-or-gust-close-button" (click)="hideContinueOptions()">
                    </div>
                    <span class="login-gust-container-title"></span>
                </div>

            </div>
            @if(cartProceedStatus==0){
            <button #proceedButton class="btn btn-primary rounded-pill py-2 px-4 col-lg-4 font-secondary mb-lg-2"
                style="font-size: 18px; margin-top:20px;" type="button" (click)="proceed()" id="myButton"
                #myButton>Proceed</button>
            }
            @if(cartProceedStatus==1){
                <button #proceedButton class="btn btn-primary rounded-pill py-2 px-4 col-lg-4 font-secondary mb-lg-2 text-dark"
                    style="font-size: 18px; margin-top:20px;" type="button" (click)="proceed()" id="myButton"
                    #myButton disabled>
                    Proceed&nbsp;<span class="spinner-border text-dark" style="height: 18px;width: 18px;"></span>
                </button>
                }
        </div>
    </div>
</div>