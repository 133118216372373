import { AfterViewInit, Component, ElementRef, HostListener, Renderer2, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';

import { MatTabsModule } from '@angular/material/tabs';
import { OOModule } from '../../modules/oo.module';
import { MatModule } from '../../modules/mat.module';
import { CommonModule, Location } from '@angular/common';
import { StorageService } from '../../services/storage/storage.service';
import { SESSION_DEPARTMENT_LIST, SESSION_FILTERED_CATEGORY_ITEM_LIST, SESSION_FILTERED_SALE_ITEM_LIST, SESSION_ITEM_CATEGORY_LIST, SESSION_OUTOFSTOCK_ITEM_LIST, SESSION_SALE_ITEM_LIST, SESSION_SELECTED_SHOP, SESSION_SELECTED_SUBCATEGORY_ID, SESSION_SYSTEM_SETTINGS } from '../../app.constants';
import { CartService } from '../../services/cart/cart.service';
import { Cart, CartItem } from '../../models/cart.model';
import { environment } from '../../environments/environment';
import { ItemListService } from '../../services/shared/item-list.service';
import { SettingsService } from '../../services/shared/settings.service';
import { TaxService } from '../../services/shared/tax.service';
import { event, get } from 'jquery';
import { UtilService } from '../../services/shared/util.service';
import { ShopItemCardComponent } from './components/shop-item-card/shop-item-card.component';
import { RewardService } from '../../services/shared/reward.service';
import { SearchItemComponent } from "./components/search-item/search-item.component";
import { fromEvent, throttleTime } from 'rxjs';
import { GtmService } from '../../services/shared/gtm.service';
import { AnimationService } from '../../services/cart/animation.service';

@Component({
    selector: 'app-shop-items',
    templateUrl: './shop-items.component.html',
    styleUrl: './shop-items.component.scss',
    imports: [OOModule, MatModule, MatTabsModule, CommonModule, RouterModule, ShopItemCardComponent, SearchItemComponent]
})
export class ShopItemsComponent implements AfterViewInit {

  tempI: any = 0;
  deliveryDateTime: string;
  deliveryDate: any;
  deliveryTime: any;
  deliveryMethod: any;
  commonCategoryList: any;
  commonSubCategoryList: any;
  isSticky: boolean = false;
  promotionalCategories: any = [];
  message: any;
  messageclass: any;
  outOfStockItemIds: any;
  shopDepartmentIds: any;
  shopItemCategories: any;
  isSearchItem: boolean = false;
  enableAutoScroll: boolean = true;
  increment() {
    this.tempI++
  }

  decrement() {
    this.tempI--
  }

  selectedIndex = 0;

  categoryList: any;
  itemList: any;electedShop: any;

  selectedShop:any;
  selectedDeliveryInfo: any;
  parentCategoryList: any = [];
  selectSubCategoryList: any = [];
  selectedItemList: any = [];

  // cart: Cart;
  selectedParentCategory: any = undefined;
  selectedSubCategory: any = undefined;
  assetsUrl: string = '';
  saleItemList: any;

  // For currency
  currencySymbol: any;
  systemSettings: any;

  // checked = false;
  // saleItemInfo: any;
  allSaleItemList: any = [];
  cartItemInfo!: CartItem;
  cart: Cart;
  cartItemId: any
  selectedQuantity: any = 1;
  // customizationDetail: any;
  remark: any = '';
  itemCategories: any;
  userenter: any;
  oldScrollTop: any;

  @ViewChild('targetElement') targetElementRef!: ElementRef<HTMLElement>;

  constructor(public dialog: MatDialog,
    private location: Location,
    private storageService: StorageService,
    public cartService: CartService,
    private itemListService: ItemListService,
    private settingsService: SettingsService,
    private taxService: TaxService,
    private router: Router,
    private renderer: Renderer2,
    private utilService: UtilService,
    private rewardService: RewardService,
    private gtmService: GtmService,
    private cartAnimationService: AnimationService) {
    this.cart = this.cartService.cart;
    this.saleItemList = this.itemListService.saleItemList;
    // console.log(this.saleItemList)
    // this.customizationDetail = [];
    this.categoryList = JSON.parse(this.storageService.loadFromSession(SESSION_FILTERED_CATEGORY_ITEM_LIST));
    this.itemList = JSON.parse(this.storageService.loadFromSession(SESSION_FILTERED_SALE_ITEM_LIST));

    const departmentList = JSON.parse(this.storageService.loadFromSession(SESSION_DEPARTMENT_LIST));
    const commonDept = departmentList.find((dept: any) => dept.code === 'COMMON');

    this.selectedShop = this.cartService.getShop();
    this.deliveryDate = this.cartService.getDeliveryDate();
    this.deliveryTime = this.cartService.getDeliveryTime();

    this.deliveryMethod = this.cartService.getService();
    this.deliveryDateTime = this.deliveryDate.date + " " + this.deliveryTime;

    var offerParentCatergories = [{ id: 999999, name: "Offer" }];
    if (this.categoryList != null && this.categoryList != undefined) {
      this.parentCategoryList = this.categoryList.filter(
        (cat: any) => cat.parentId === 0 && cat.departmentId !== commonDept.id
      );
      this.commonCategoryList = this.categoryList.filter(
        (cat: any) => cat.parentId === 0 && cat.departmentId == commonDept.id
      );
    }

    this.parentCategoryList.push(...offerParentCatergories);

    // if(this.commonCategoryList!=null && this.commonCategoryList != undefined){
    var commonSubCategoryIDs = this.commonCategoryList.flatMap((cat: any) => cat.subItemCategoryIds);
    this.commonSubCategoryList = this.categoryList.filter((cat: any) => commonSubCategoryIDs.includes(cat.id))
    // }



    this.promotionalCategories = this.rewardService.getPromotionalCategoriesWithItems();
    this.onParentCategorySelected(this.parentCategoryList[0]);

    this.assetsUrl = environment.oos.assetsUrl;

    //For currency settings
    this.systemSettings = JSON.parse(this.storageService.loadFromSession(SESSION_SYSTEM_SETTINGS));
    this.currencySymbol = this.systemSettings.currency.symbol;
    if (this.systemSettings === null || this.systemSettings === undefined) {
      this.settingsService.loadSystemSettings().subscribe(
        res => {
          this.systemSettings = res.object;
          this.storageService.saveToSession(SESSION_SYSTEM_SETTINGS, JSON.stringify(this.systemSettings));
          this.currencySymbol = this.systemSettings.currency.symbol;
        }
      );
    }
    this.itemCategories = JSON.parse(this.storageService.loadFromSession(SESSION_ITEM_CATEGORY_LIST));
    this.shopDepartmentIds = JSON.parse(this.storageService.loadFromSession(SESSION_SELECTED_SHOP)).department;
    this.shopItemCategories = JSON.parse(this.storageService.loadFromSession(SESSION_SELECTED_SHOP)).item_category;
    this.outOfStockItemIds = JSON.parse(this.storageService.loadFromSession(SESSION_OUTOFSTOCK_ITEM_LIST));

    fromEvent(window, 'scroll')
      .pipe(throttleTime(100))
      .subscribe((event) => this.onWindowScroll(event));
  }

  /**
   * Logic to handle scroll events will be here
   * @param event 
   */
  onWindowScroll(event: Event) {
    // console.log('scrolling');
    if (!this.enableAutoScroll) return;
    let scrollPosition = window.scrollY || document.documentElement.scrollTop;
    scrollPosition = scrollPosition + 500;
    const sections: any = document.querySelectorAll('.section');
    sections.forEach((section: HTMLElement) => {
      if (
        section.offsetTop <= scrollPosition &&
        section.offsetTop + section.offsetHeight > scrollPosition
      ) {
        let navLinks: any = [];
        this.selectSubCategoryList.forEach((item: any) => {
          if (this.isValidCatergory(item)) {
            navLinks.push({ ele: "pills-sub-cat-" + item.code.toString().toLowerCase(), code: item.code });
          }
        });
        navLinks.forEach((link: any) => {
          let navTab: any = document.querySelectorAll('#' + link.ele);
          if (link.ele + "-tab" == section.id) {
            const newCat = this.selectSubCategoryList.find((cat: any) => cat.code == link.code);
            if (newCat !== null) {
              this.selectedSubCategory = newCat;
            }
            // navTab[0].classList.add('active');
            navTab[0].classList.forEach((a: any) => {
              if (a == 'active') {
                this.scrollToElement(navTab[0])
              }
            });
          } else {
            // navTab[0].classList.remove('active');
          }
        });
      }
    });
  }

  /**
   * Find the item in the view
   * @param targetElement 
   */
  scrollToElement(targetElement: any) {
    if (targetElement) {
      // const container = targetElement.parentElement;
      // const offset = targetElement.offsetLeft;
      // container!.scrollLeft = offset;
      targetElement.scrollIntoView({ inline: "nearest" });
    }
  }

  @ViewChild('itemCatDiv', { static: true }) stickyDiv!: ElementRef;
  ngAfterViewInit(): void {

    const clienthWidth = this.stickyDiv.nativeElement.clientWidth;
    this.renderer.setStyle(this.stickyDiv.nativeElement, 'max-width', `${clienthWidth}px`);

  }

  @HostListener('window:scroll')
  onScroll() {
    const currentScrollPosition = window.scrollY;
    this.isSticky = currentScrollPosition > 120;
  }
  ngOnInit() {
    this.utilService.scrollToTop();
  }

  navigateToshopList() {
    this.router.navigate(['/shopList']);
  }
  /**
   * set the parent category;
   * @param pCat 
   */
  onParentCategorySelected(pCat: any): void {
    this.selectedParentCategory = pCat;
    this.selectSubCategoryList = this.getSubCategoryList(pCat);
    this.selectedSubCategory = this.selectSubCategoryList[0];
  }

  onSubCategorySelected(sCat: any): void {
    this.selectedSubCategory = sCat;
    this.scrollToSubCategory(sCat);
  }
  /**
   * Scroll to the sub category div
   * @param cat 
   */
  scrollToSubCategory(cat: any): void {
    const targetId = "pills-sub-cat-" + cat.code.toLowerCase() + "-tab";
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      this.enableAutoScroll = false;
      window.addEventListener("scrollend", () => {
        // setTimeout(() => {
        this.enableAutoScroll = true;
        // }, 500);

        window.removeEventListener("scrollend", () => { });
      });
      // targetElement.scrollIntoView({block:"nearest",inline:"nearest" });
      // targetElement.scrollIntoView({inline:"end" });
      // targetElement.scrollTo({top:160});

      this.scrollToElementWithOffset(targetElement, 270);
    }
  }

  /**
   * Scroll the element to top with offset
   * @param element 
   * @param offset 
   */
  scrollToElementWithOffset(element: any, offset: any): void {
    const elementRect = element.getBoundingClientRect();
    const elementHeight = elementRect.height;
    const currentScrollTop = window.scrollY || document.documentElement.scrollTop;
    const targetPosition = elementRect.top + currentScrollTop - offset;

    window.scrollTo({ top: targetPosition, behavior: "smooth" });
  }
  /**
   * Returns the sub categories base on selected parent category
   * @param parentCategory 
   * @returns 
   */
  getSubCategoryList(category: any): any[] {
    // console.log("category"+JSON.stringify(category))
    var selectSubCategoryList = (category.id == 999999) ? this.promotionalCategories : this.categoryList.filter((cat: any) => category.subItemCategoryIds.includes(cat.id));
    return (category.id == 999999) ? selectSubCategoryList : [...selectSubCategoryList, ...this.commonSubCategoryList];
  }

  /**
   * Validates the category for displaying
   * @param cat 
   * @returns 
   */
  isValidCatergory(cat: any): boolean {
    var isValid = false;
    if (cat !== undefined && cat !== null) {
      for (var index = 0; index < cat.saleItemIds.length; index++) {
        var saleItem = this.itemList.find((si: any) => si.id == cat.saleItemIds[index]);
        if (saleItem !== undefined && saleItem !== null && saleItem.isDirectSaleAllowed == 1 && this.isItemAvailableDay(saleItem)) {
          isValid = true;
          break;
        }
      }
    }
    return isValid;
  }

  /**
   * Checks the items avaiable for the selected day.
   * @param saleItem 
   * @returns 
   */
  isItemAvailableDay(saleItem: any): boolean {

    let pickUpDay = new Date(this.cart.deliveryDate.date).getDay();
    if (pickUpDay === 0) {
      pickUpDay = 7;
    }
    return saleItem.itemAvailableDay.findIndex((e: any) => e === pickUpDay) > -1;
  }

  /**
 * Returns the item list based on selected sub category
 * @param parentCategory 
 * @returns 
 */
  getItemList(category: any) {
    const selectedItemList = this.itemList.filter((item: any) => category.saleItemIds.includes(item.id));
    return selectedItemList;
  }

  addToCart(data: any) {
    this.allSaleItemList = JSON.parse(this.storageService.loadFromSession(SESSION_SALE_ITEM_LIST));
    // this.cartItemInfo = this.cart.items.find((cs:any) => cs.itemId === id);
    // console.log(this.cartItemInfo)
    var saleItemInfo = JSON.parse(JSON.stringify(this.allSaleItemList.find((asl: any) => asl.saleItemId == data.id)));
    // this.checked = false;
    // this.isCustomizable();
    // this.cartAnimServce.addToCartAnimation('item-image-panel', 'checkout-btn');
    // this.cartAnimServce.scalAnim('cart-update-anim');
    let item: CartItem;
    // console.log(this.saleItemInfo)
    const customizationDetail=this.getCustomizationDetails(saleItemInfo);
    const offerData = this.rewardService.getOfferDataOfItem(saleItemInfo);
    if(offerData !== null && offerData !== undefined){
      item = {
        itemId: 0,
        itemType:saleItemInfo.itemType,
        customerRewardId: offerData.customer_reward_id,
        ooSaleItemId: saleItemInfo.id, saleItemId: saleItemInfo.saleItemId, quantity: this.selectedQuantity,
        fixedPrice: (saleItemInfo.itemType!==3)?saleItemInfo.fixedPrice:0,
        totalPrice: (saleItemInfo.itemType!==3)?(saleItemInfo.fixedPrice * this.selectedQuantity):0,
        unitPriceWithTax: (saleItemInfo.itemType!==3)?saleItemInfo.unitPriceWithTax:0,
        customizationUnitPriceWithTax: 0,
        isCustomized: false, 
        customization:  (saleItemInfo.itemType==3)? []:JSON.parse(JSON.stringify(customizationDetail)), 
        comboOptions: (saleItemInfo.itemType!==3)? []:JSON.parse(JSON.stringify(customizationDetail)), 
        remarks: this.remark,
        taxCalculationMethod: saleItemInfo.taxCalculationMethod, tax1Percentage: saleItemInfo.tax1Percentage,
        netCustomizationTotal: 0,
        netTotal: 0, totalTax: 0, customizationTotalTax: 0
      };
    } else {
    item = {
      itemId: 0,
      itemType:saleItemInfo.itemType,
      ooSaleItemId: saleItemInfo.id, saleItemId: saleItemInfo.saleItemId, quantity: this.selectedQuantity,
      fixedPrice: (saleItemInfo.itemType!==3)?saleItemInfo.fixedPrice:0,
      totalPrice: (saleItemInfo.itemType!==3)?(saleItemInfo.fixedPrice * this.selectedQuantity):0,
      unitPriceWithTax: (saleItemInfo.itemType!==3)?saleItemInfo.unitPriceWithTax:0,
      customizationUnitPriceWithTax: 0,
      isCustomized: false, 
      customization:  (saleItemInfo.itemType==3)? []:JSON.parse(JSON.stringify(customizationDetail)), 
      comboOptions: (saleItemInfo.itemType!==3)? []:JSON.parse(JSON.stringify(customizationDetail)), 
      remarks: this.remark,
      taxCalculationMethod: saleItemInfo.taxCalculationMethod, tax1Percentage: saleItemInfo.tax1Percentage,
      netCustomizationTotal: 0,
      netTotal: 0, totalTax: 0, customizationTotalTax: 0
    };
  }
    if(item.itemType==3){
      this.cartService.setCombo(item);
    }else{
      this.cartService.setCustomization(item);
    }
    this.taxService.reCalculateTax(item);
    this.cartService.addItem(item);
    this.rewardService.checkForOffer(item);
    const imageclass = data.classname;
    this.cartAnimationService.addToCartAnimation(imageclass, 'checkout-btn');
  }

  getCustomizationDetails(item:any):any{
    var customizations=undefined;
    if(item.itemType==3){
     customizations=this.cartService.getComboOptionDetail(item);;
    }
    return (customizations)?customizations:[];
  }

  // setSaleItemForCustomization(item:any){
  //   const  customizations= this.getCustomizationDetails(item);
  //   item.customization=customizations;
  //   if(item.itemType==3){
  //     item.fixedPrice=0;
  //     item.
  //   }
  // }

  // isCustomizable() {
  //   for (let cd of this.customizationDetail) {
  //     for (let ov of cd.optionValue) {
  //       if (ov.isDefault === 0 && ov.isSelected === 1) {
  //         this.checked = true;
  //         return;
  //       }
  //     }
  //   }
  // }


  /**
  * GTM CODE BRGINS
  */

  /**
   * Handle back button press
   */
  handleGTMPageEngagement(previousUrl: string) {
    this.gtmService.pushEvent(
      'page_engagement',
      {
        actionType: 'Back to Menu',
        brandName: this.gtmService.getItemCatgoryName(this.selectedParentCategory.id),
        categoryName: this.selectedSubCategory.name,
        storeSelected: this.gtmService.getStore(),
        visitorType: this.gtmService.getVisitorType(),
        dateSelectd: this.gtmService.geDeliveryDate(),
        timeSelected: this.gtmService.getDeliveryTime(),
        preOrder: this.gtmService.isPreOrder()
      });
  }

  /**
   * GTM CODE END
   */
  // getsub(sub: any) {
  //   console.log(sub)
  // }

  // itemSelected(_$event: any) {
  //   if (_$event.index == 0) {
  //     this.selectedSubCategoryId = 38
  //   }
  //   else if (_$event.index == 1) {
  //     this.selectedSubCategoryId = 1
  //   }
  //   else if (_$event.index == 2) {
  //     this.selectedSubCategoryId = 3
  //   }
  //   else {
  //     this.selectedSubCategoryId = 4
  //   }
  //   this.storageService.saveToSession(SESSION_SELECTED_SUBCATEGORY_ID, JSON.stringify(this.selectedSubCategoryId));
  // }

  onTabChange(_$event: any) {
    if (_$event.index == 2) {
      this.dialog.open(PlatterComponent, {
        panelClass: 'platter-dialog',
        disableClose: true
      })
      this.selectedIndex = 0
    }
  }

  itemSelected(saleItem: any) {
    this.storageService.saveToSession(SESSION_SELECTED_SUBCATEGORY_ID, JSON.stringify(saleItem.saleItemId));
    if(saleItem.itemType == 3){
      this.router.navigate(['/comboDetail'], { queryParams: { 'source': '', 'id': saleItem.saleItemId } });
    }
    else {
      this.router.navigate(['/itemDetail'], { queryParams: { 'source': '', 'id': saleItem.saleItemId } });
    }
  }

  back() {
    this.location.back();
  }

  searchAllItems() {
    this.isSearchItem = true;
  }

  // itemClick(itemName:any) {
  //   this.userenter = itemName;
  //   this.router.navigate(['/all-item-list'], { queryParams: { 'search': this.userenter } });
  // }

  backClick(event: any) {
    this.isSearchItem = false;
  }
  // isItemAvailable(item: any) {
  //   let itemAvailable = true;
  //   // console.log(this.outOfStockItemIds)
  //   const itemCatIndex = this.itemCategories.findIndex((obj:any) => obj.id === item.itemCategoryId);
  //   if (item.ooBusinessTypeId === null ||
  //     !(item.ooBusinessTypeId.findIndex((bt:any) => bt === this.cart.businessType.id) > -1)) {
  //     itemAvailable = false;
  //     this.message = 'ITEM NOT AVAILABLE FOR ' + this.cart.businessType.name;
  //   } else if (this.shopDepartmentIds === null || ((itemCatIndex !== -1) &&
  //     !(this.shopDepartmentIds.findIndex((obj:any) => obj === this.itemCategories[itemCatIndex].departmentId) >= 0))) {
  //     itemAvailable = false;
  //   } else if (this.shopItemCategories !== null && !(this.shopItemCategories.findIndex((obj:any) => obj === this.saleItemList.itemCategoryId) >= 0)) {
  //     itemAvailable = false;
  //   } else if (!this.availableDay(item)) {
  //     itemAvailable = false;
  //     this.message = 'ITEM NOT AVAILABLE ON ' + this.cart.deliveryDate.label;
  //   }else if(this.outOfStockItemIds.includes(item.id)){
  //     itemAvailable = false;
  //     this.message = 'OUT OF STOCK';
  //     this.messageclass = 'na-button1';
  //   }
  //   return itemAvailable;
  // }

  // availableDay(item:any) {

  //   let pickUpDay = new Date(this.cart.deliveryDate.date).getDay();
  //   if (pickUpDay === 0) {
  //     pickUpDay = 7;
  //   }
  //   return item.itemAvailableDay.findIndex((e:any) => e === pickUpDay) > -1;
  // }
}

@Component({
    selector: 'app-platter',
    template: `
  <section>
  <button class="btn btn-close shadow-none opacity-100 position-absolute top-0 end-0 m-4" (click)="close()"></button>
    <h3 class="font-secondary mb-4 pb-2">Platter Info</h3>
    <p>Dear customer, by choosing Platters, you will exit from this page and your cart will be emptied.
       Kindly re-select the preferred shop / pickup date & time in view of a minimum pre-order lead time 
       of 24-hours for all catering / platter items. Click ok to proceed.</p>
    <div class="text-end pt-2 mt-4 font-secondary">
      <button class="btn btn-primary py-2 px-4 rounded-pill" routerLink="recentShops" (click)="close()">Ok</button>
    </div>
  </section>`,
    standalone: false
})

export class PlatterComponent {
  constructor(public dialogRef: MatDialogRef<PlatterComponent>, private router: Router) { }
  close() {
    this.dialogRef.close()
  }
}
