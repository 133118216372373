import { Component } from '@angular/core';
import { CommonModule, Location } from '@angular/common'
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { StorageService } from '../../services/storage/storage.service';
import { PRODUCT_IMAGE_FOLDER, SESSION_FILTERED_SALE_ITEM_LIST, SESSION_ITEM_CATEGORY_LIST, SESSION_OUTOFSTOCK_ITEM_LIST, SESSION_SALE_ITEM_LIST, SESSION_SELECTED_SHOP, SESSION_SYSTEM_SETTINGS } from '../../app.constants';
import { environment } from '../../environments/environment';
import { CartService } from '../../services/cart/cart.service';
import { Cart, CartItem } from '../../models/cart.model';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { SettingsService } from '../../services/shared/settings.service';
import { StartupService } from '../../services/shared/startup.service';
import { TaxService } from '../../services/shared/tax.service';
import { UtilService } from '../../services/shared/util.service';
import { RewardService } from '../../services/shared/reward.service';
import { PromotionCardComponent } from '../home/components/promotion-card/promotion-card.component';
import { GtmService } from '../../services/shared/gtm.service';
import { ComboService } from '../../services/shared/combo.service';
import { AnimationService } from '../../services/cart/animation.service';
// import { ItemImagesComponent } from "./item-images/item-images.component";

@Component({
    selector: 'app-item-detail',
    imports: [MatExpansionModule, RouterModule, MatFormFieldModule, MatSelectModule, FormsModule, CommonModule],
    templateUrl: './item-detail.component.html',
    styleUrl: './item-detail.component.scss'
})
export class ItemDetailComponent {
  id: any;
  itemDetails: any;
  filteredSaleItemList: any;
  assetsUrl: string = '';
  itemCategories: any;
  saleItemInfo: any;
  cart: Cart;
  message: any;
  shopDepartmentIds: any;
  shopItemCategories: any = [];
  sourceParam: any;
  currentSaleItemId: any;
  shopName: any;
  allSaleItemList: any = [];
  customizationDetail: any;
  quantity: any = [];
  selectedQuantity: any = 1;
  currencySymbol: any;
  systemSettings: any;
  currentSubCategoryId: any;
  selectedSubCategory: any;
  selectedParentCategory: any;
  outOfStockItemIds: any;
  outOfStockItemInfo: any;
  customizationUnitPriceWithTax: any;
  customizationUnitTotalTax: number;
  CurrentCustzdOptnSelected: any;
  shop: any;
  uploadUrl: any;
  imageUrl: any;
  images: any[] = [];
  checked = false;
  remark: any = '';
  cartItemInfo?: CartItem;
  checkButton = false;
  promotions: any;
  offer: any;
  offerList: any;
  crmAssetsUrl = environment.crm.assetsUrl;
  isValid: boolean = false;
  isActiveCustomization: any;
  comboOptionId: any;
  cId: any;

  constructor(private location: Location, public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private storageService: StorageService,
    private cartService: CartService,
    private _settingsService: SettingsService,
    private startUp: StartupService,
    private taxService: TaxService,
    private utilService: UtilService,
    private rewardService: RewardService,
    private gtmService: GtmService,
    private comboService:ComboService,
  private cartAnimServce:AnimationService) {
    this.cart = this.cartService.cart;
    this.assetsUrl = environment.oos.assetsUrl;
    this.customizationUnitPriceWithTax = 0;
    this.customizationUnitTotalTax = 0;
    this.CurrentCustzdOptnSelected = '';
    this.sourceParam = this.route.snapshot.queryParams['source'];
    this.shop = JSON.parse(this.storageService.loadFromSession(SESSION_SELECTED_SHOP));
    this.shopName = this.shop.name
    this.filteredSaleItemList = JSON.parse(this.storageService.loadFromSession(SESSION_FILTERED_SALE_ITEM_LIST));
    this.outOfStockItemIds = JSON.parse(this.storageService.loadFromSession(SESSION_OUTOFSTOCK_ITEM_LIST));
    this.outOfStockItemInfo = this.filteredSaleItemList.filter((fs: any) => this.outOfStockItemIds.findIndex((os: any) => fs.id === os) >= 0);
    this.allSaleItemList = JSON.parse(this.storageService.loadFromSession(SESSION_SALE_ITEM_LIST));

    this.itemDetails = this.filteredSaleItemList.find((s: any) => s.id == this.id);
    this.customizationDetail = [];
    if (this.sourceParam === 'combo') {
      this.cId=this.route.snapshot.queryParams['cid'];
      this.comboOptionId = this.route.snapshot.queryParams['coid'];
      this.currentSaleItemId = this.route.snapshot.queryParams['id'];
      var saleItemToEdit=this.comboService.getSaleItem(this.comboOptionId,this.currentSaleItemId);
      this.saleItemInfo=JSON.parse(JSON.stringify(saleItemToEdit));
      var comboOptionValue=this.comboService.getComboOptionValueBySaleItem(this.comboOptionId,this.currentSaleItemId);
      if(comboOptionValue.customization){
        this.customizationDetail =  JSON.parse(JSON.stringify(comboOptionValue.customization));
        this.customizationUnitPriceWithTax = ((comboOptionValue.customizationUnitPriceWithTax !== undefined) ? comboOptionValue.customizationUnitPriceWithTax : 0);
      }else{
        this.customizationDetail = this.cartService.getCustomizationDetail(this.saleItemInfo);
      }
      this.remark=comboOptionValue.remark
    }else if (this.sourceParam === 'shoppingCart') {
      const cartItemId = this.route.snapshot.queryParams['id'];
      this.checkButton = true;
      //Changed = to != and ? added otherwise getting error
      this.cartItemInfo = this.cart.items?.find((cs: any) => cs.itemId === cartItemId);
      if (this.cartItemInfo === undefined) {
        this.router.navigate(['/shoppingCart']);
      }
      this.saleItemInfo = this.allSaleItemList.find((asl: any) => asl.id == this.cartItemInfo?.ooSaleItemId);
      this.currentSaleItemId = this.saleItemInfo.saleItemId;
      this.selectedQuantity = this.cartItemInfo?.quantity;
      this.customizationUnitPriceWithTax = ((this.cartItemInfo?.customizationUnitPriceWithTax !== undefined) ? this.cartItemInfo.customizationUnitPriceWithTax : 0);
      this.remark = this.cartItemInfo?.remarks;
      this.customizationDetail = this.cartItemInfo?.customization;
      if (this.customizationDetail.length > 0) {
        this.customizationDetail = JSON.parse(JSON.stringify(this.customizationDetail));
      } else {
        this.customizationDetail = this.cartService.getCustomizationDetail(this.saleItemInfo);
      }
    } else {
      this.currentSaleItemId = this.route.snapshot.queryParams['id'];
      this.saleItemInfo = this.allSaleItemList.find((asl: any) => asl.saleItemId == this.currentSaleItemId);
      this.customizationDetail = this.cartService.getCustomizationDetail(this.saleItemInfo);
    }
    this.shopItemCategories = this.shop.item_category;
    this.itemCategories = JSON.parse(this.storageService.loadFromSession(SESSION_ITEM_CATEGORY_LIST));
    this.shopDepartmentIds = this.shop.department;

    this.currentSubCategoryId = this.saleItemInfo.itemCategoryId;
    this.selectedSubCategory = this.itemCategories.find((ic: any) => ic.id === this.currentSubCategoryId);
    if(this.selectedSubCategory!==null && this.selectedSubCategory!==undefined){
    this.selectedParentCategory = this.itemCategories.find((ic: any) => ic.id === this.selectedSubCategory.parentId);
    if (this.selectedParentCategory.code === 'BENTO') {
      this.message = 'AVAILABLE ONLY IN SHOPS SELLING BENTO BOWL';

    } else {
      this.message = 'TEMPORARILY UNAVAILABLE';

    }
  }
    this.getQuantity();
    this.systemSettings = JSON.parse(this.storageService.loadFromSession(SESSION_SYSTEM_SETTINGS));
    this.currencySymbol = this.systemSettings.currency.symbol;
    if (this.systemSettings === null || this.systemSettings === undefined) {
      this._settingsService.loadSystemSettings().subscribe(
        res => {
          this.systemSettings = res.object;
          this.storageService.saveToSession(SESSION_SYSTEM_SETTINGS, JSON.stringify(this.systemSettings));
          this.currencySymbol = this.systemSettings.currency.symbol;
        }
      );
    }
  }

  ngOnInit() {
    this.utilService.scrollToTop();
    // this.statusBarService.parent = this;
    this.uploadUrl = environment.oos.assetsUrl;
    this.imageUrl = this.uploadUrl + PRODUCT_IMAGE_FOLDER;
    this.getSaleInfoImages();
    this.updateGTMInfo('view_item');
    this.offer = this.rewardService.getItemPromotion(this.saleItemInfo.saleItemId)
    this.setOfferList(this.offer);
    this.promotions = this.rewardService.hasPromotion(this.saleItemInfo.saleItemId);
  }

  setOfferList(offer: any) {
    if (offer && offer.buy && offer.buy.length > 0) {
      this.offerList = offer.buy;
    }
    else if (offer && offer.offer && offer.offer.length > 0) {
      this.offerList = offer.offer;
    }
  }

  getQuantity() {
    this.quantity = [
      { label: '1', value: 1 },
      { label: '2', value: 2 },
      { label: '3', value: 3 },
      { label: '4', value: 4 },
      { label: '5', value: 5 },
      { label: '6', value: 6 },
      { label: '7', value: 7 },
      { label: '8', value: 8 },
      { label: '9', value: 9 },
      { label: '10', value: 10 },
    ];
  }

  isItemAvailable() {
    let itemAvailable = true;
    const itemCatIndex = this.itemCategories.findIndex((obj: any) => obj.id === this.saleItemInfo.itemCategoryId);

    if (this.saleItemInfo.ooBusinessTypeId === null ||
      !(this.saleItemInfo.ooBusinessTypeId.findIndex((bt: any) => bt === this.cart.businessType.id) > -1)) {
      itemAvailable = false;
      this.message = 'ITEM NOT AVAILABLE FOR ' + this.cart.businessType.name;
    } else if (this.shopDepartmentIds === null ||
      !(this.shopDepartmentIds.findIndex((obj: any) => obj === this.itemCategories[itemCatIndex].departmentId) >= 0)) {
      itemAvailable = false;
    } else if (this.shopItemCategories !== null && !(this.shopItemCategories.findIndex
      ((obj: any) => obj === this.saleItemInfo.itemCategoryId) >= 0)) {
      itemAvailable = false;
    } else if (!this.availableDay()) {
      itemAvailable = false;
      this.message = 'ITEM NOT AVAILABLE on ' + this.cart.deliveryDate.label;
    }
    return itemAvailable;
  }

  availableDay() {

    let pickUpDay = new Date(this.cart.deliveryDate.date).getDay();
    if (pickUpDay === 0) {
      pickUpDay = 7;
    }
    return this.saleItemInfo.itemAvailableDay.findIndex((e: any) => e === pickUpDay) > -1;
  }

  getSaleInfoImages() {
    if (this.saleItemInfo.images !== null) {
      for (let count = 0; count < this.saleItemInfo.images.length; count++) {
        this.images.push({
          source: this.imageUrl + this.saleItemInfo.images[count],
          alt: ' Description for Image 1', title: 'Title 1'
        });
      }
    } else {
      this.images.push({
        source: 'assets/no-image-available.png',
        alt: '  No image', title: 'No image'
      });
    }
  }

  redirectToShop() {
    this.router.navigate(['/storefinder']);
  }

  addToCart() {
    this.checked = false;
    this.isCustomizable();
    this.cartAnimServce.addToCartAnimation('item-image-panel', 'checkout-btn');
    this.cartAnimServce.scalAnim('cart-update-anim');
    let item: CartItem;
    item = {
      itemId: 0,
      itemType:this.saleItemInfo.itemType,
      ooSaleItemId: this.saleItemInfo.id, saleItemId: this.saleItemInfo.saleItemId, quantity: this.selectedQuantity,
      fixedPrice: this.saleItemInfo.fixedPrice,
      totalPrice: (this.saleItemInfo.fixedPrice * this.selectedQuantity),
      unitPriceWithTax: this.saleItemInfo.unitPriceWithTax,
      customizationUnitPriceWithTax: 0,
      isCustomized: this.checked, 
      customization: JSON.parse(JSON.stringify(this.customizationDetail)), 
      comboOptions:[],
      remarks: this.remark,
      taxCalculationMethod: this.saleItemInfo.taxCalculationMethod, tax1Percentage: this.saleItemInfo.tax1Percentage,
      netCustomizationTotal: 0,
      customizationTotalTax: 0,
      netTotal: 0, totalTax: 0,
      customizationOfferAmount: 0,
      offerAmount: 0
    };
    this.cartService.setCustomization(item);
    this.taxService.reCalculateTax(item);
    this.cartService.addItem(item);

    this.updateGTMInfo('add_to_cart');
  }

  setComboCustomization(){

    var optionValue= this.comboService.getComboOptionValueBySaleItem(this.comboOptionId,this.currentSaleItemId);
    optionValue.customization=JSON.parse(JSON.stringify(this.customizationDetail));
    optionValue.remark=this.remark;
    optionValue.quantity=this.selectedQuantity;
    this.cartService.setCustomization(optionValue);
    this.taxService.reCalculateTax(optionValue);
    if (this.sourceParam === 'combo') {
      this.router.navigate(['/comboDetail'], { queryParams: { 'source': 'cust', 'cid':this.cId} });
    }
  }

  updateCart() {
    // this.cartAnimServce.itemAddedToCart('checkout-btn');
    /**
     * for checking , is customized or not
     */
    this.checked = false;
    this.isCustomizable();

    this.cartItemInfo!.quantity = this.selectedQuantity,
      this.cartItemInfo!.totalPrice = (this.saleItemInfo.unitPriceWithTax * this.selectedQuantity);
    this.cartItemInfo!.isCustomized = this.checked;
    this.cartItemInfo!.customization = this.customizationDetail;
    this.cartService.setCustomization(this.cartItemInfo);

    this.cartItemInfo!.remarks = this.remark;
    if (this.sourceParam === 'shoppingCart') {
      this.router.navigate(['/shoppingCart']);
    }
    this.cartService.changeItem(this.cartItemInfo);
  }

  isCustomizable() {
    for (let cd of this.customizationDetail) {
      for (let ov of cd.optionValue) {
        if (ov.isDefault === 0 && ov.isSelected === 1) {
          this.checked = true;
          return;
        }
      }
    }
  }

  nutritionalAccordionOpen() {

    const ement = document.querySelector('.item-nutritional-info-span');
    this.scrollToView(ement);

  }

  descriptionAccordionOpen() {

    const ement = document.querySelector('.item-description-span');
    this.scrollToView(ement);

  }

  customizationAccordionOpen(optIndex: any) {

    const ement = document.querySelector('#customization-option_' + optIndex);
    this.scrollToView(ement);

  }

  scrollToView(element: any) {
    setTimeout(() => {
      element.scrollIntoView({
        behavior: "smooth",
        block: "nearest"
      });
    }, 500);
  }

  selectCustomization(selectedValue: any, customizationOption: any) {
    this.removeOptionVal(customizationOption.optionValue);
    selectedValue.isSelected = 1;
    this.customizationUnitPriceWithTax += (selectedValue.isDefault) ? 0 : selectedValue.unitPriceWithTax;
  }

  removeOptionVal(customizationOptionValues: any) {
    for (let elem of customizationOptionValues) {
      if (elem.isSelected == 1) {
        this.customizationUnitPriceWithTax += (elem.isDefault) ? 0 : (-elem.unitPriceWithTax);
        this.customizationUnitTotalTax += (elem.isDefault) ? 0 : (-elem.tax);
        elem.isSelected = 0;
        return;
      }
    }
  }

  getCustomizationDisplayName(customizationData: any) {
    let dispName = '';
    let custOptData = customizationData.optionValue.find((co: any) => co.isDefault !== 1 && co.isSelected === 1)
    if (custOptData !== undefined) {
      dispName = custOptData.name + ' : ' + this.currencySymbol + parseFloat(custOptData.unitPriceWithTax).toFixed(2);
    }
    return dispName;
  }

  isValidCustOptVal(custOptVal: any) {
    let isValid = true;
    if (custOptVal.saleItemId !== null) {
      isValid = this.filteredSaleItemList.some((oi: any) => custOptVal.saleItemId === oi.saleItemId);
    }
    if (isValid && this.saleItemInfo.itemCustOptnValuesInactive !== null) {
      isValid = !this.saleItemInfo.itemCustOptnValuesInactive.some((oi: any) => oi == custOptVal.id);
    }
    return isValid;
  }

  isOutOfStockCustOptVal(custOptVal: any): boolean {
    this.isValid = true;
    if (custOptVal.saleItemId !== null && this.outOfStockItemInfo !== undefined && this.outOfStockItemInfo.length > 0) {
      this.isValid = !this.outOfStockItemInfo.some((oi: any) => custOptVal.saleItemId === oi.saleItemId);
    }

    return this.isValid;
  }

  /**
  * Enter key press prevented in cart item remarks.
  * ie, new line not allowed.
  * @param event 
  * @returns 
  */
  onKeydown(event: any) {
    if (event.keyCode === 13) {
      event.preventDefault();
      return false;
    }
    else {
      return true;
    }
  }

  /**
   * Extract the selected customizations
   * @returns 
   */
  getSelectedCustomizations(): any {
    let selectedCustomization = [];
    let custTotalPrice = 0;
    for (let cd of this.customizationDetail) {
      for (let ov of cd.optionValue) {
        if (ov.isSelected === 1 && ov.isDefault == 0) {
          selectedCustomization.push(ov);
          custTotalPrice += ov.fixedPrice;
        }
      }
    }
    return { selectedCustomization: selectedCustomization, total: custTotalPrice };

  }

  /**
* Update the GTM info
*/
  goToCart() {
    this.updateGTMInfo('view_cart');
  }

  updateGTMInfo(event: string): void {

    this.gtmService.pushEvent(
      '',
      {
        userId: this.gtmService.getCustomer(),
        visitorType: this.gtmService.getVisitorType(),
        dateSelectd: this.gtmService.geDeliveryDate(),
        timeSelected: this.gtmService.getDeliveryTime(),
        preOrder: this.gtmService.isPreOrder()

      });

    let items = [];

    items.push({
      item_id: this.saleItemInfo.code,
      item_name: this.saleItemInfo.name,
      affiliation: this.gtmService.getStore(),
      item_brand: this.selectedParentCategory.name,
      item_category: this.selectedSubCategory.name,
      item_variant: '',
      price: this.saleItemInfo.unitPriceWithTax * this.selectedQuantity,
      quantity: this.selectedQuantity

    });
    //{selectedCustomization:selectedCustomization,total:custTotalPrice};
    let customization = this.getSelectedCustomizations();

    if (customization.selectedCustomization.length > 0) {
      items.push(
        {
          item_id: this.saleItemInfo.code,
          item_name: this.saleItemInfo.name,
          item_category2: (customization.selectedCustomization.length > 0) ? customization.selectedCustomization[0].nameToPrint : '',
          item_category3: (customization.selectedCustomization.length > 1) ? customization.selectedCustomization[1].nameToPrint : '',
          item_category4: (customization.selectedCustomization.length > 2) ? customization.selectedCustomization[2].nameToPrint : '',
          item_category5: (customization.selectedCustomization.length > 3) ? customization.selectedCustomization[3].nameToPrint : '',
          price: customization.total * this.selectedQuantity,
          quantity: this.selectedQuantity
        }
      );
    }

    this.gtmService.pushG4AEvent(
      event,
      {
        ecommerce: {
          currency: 'NZD',
          value: (this.saleItemInfo.unitPriceWithTax + this.customizationUnitPriceWithTax) * this.selectedQuantity,
          items: items
        }
      });
  }

  /**
   * Handle back button press
   */

  handleGTMPageEngagement(previousUrl: string) {
    if (previousUrl.startsWith('/item-list') || previousUrl.startsWith('/cart')) {
      this.gtmService.pushEvent(
        'page_engagement',
        {
          actionType: (previousUrl.startsWith('/cart')) ? 'Back to Order details' : 'Back to Item List',
          brandName: this.gtmService.getItemCatgoryName(this.selectedParentCategory.id),
          categoryName: this.selectedSubCategory.name,
          storeSelected: this.gtmService.getStore(),
          visitorType: this.gtmService.getVisitorType(),
          dateSelectd: this.gtmService.geDeliveryDate(),
          timeSelected: this.gtmService.getDeliveryTime(),
          preOrder: this.gtmService.isPreOrder()
        });
    }
  }

  back() {
    this.location.back()
  }

  step: any;

  setStep(index: number) {
    this.step = index;
  }

  tempI: any = 1;

  increment() {
    this.tempI++
  }

  decrement() {
    this.tempI--
  }

}
